.SignaturePad {
  border: solid 1px black;
  width: 100%;
  height: 100%;
}
.canvascontainer {
  margin: auto;
  width: 80%;
  height: 40vh;
}

@media (max-width: 500px) {
  .SignaturePad {
    border: solid 1px black;
    width: 100%;
    height: 100%;
  }
  .canvascontainer {
    margin: auto;
    width: 80%;
    height: 40vh;
  }
}
