.container {
  background-image: url(./images/Asset.png);
  background-size: auto;
  overflow: auto;
  display: grid;
  grid-template-columns: 25vw 50vw 25vw;
  grid-template-areas: ". form-border .";
  color: rgb(102, 209, 229);
}

.label-title {
  font-size: 2.5vw;
  font-weight: bold;
}
.form-label {
  display: block;
  text-align: left;
  font-size: 1.25vw;
}
.form-border {
  grid-area: form-border;
  display: grid;
  grid-template-columns: 6% 88% 6%;
  grid-template-areas:
    ". . ."
    ". form."
    ". . .";
  border: solid 5px white;
  border-radius: 20px;
  margin-top: 3%;
  margin-bottom: 3%;
}

.form-container {
  grid-area: form;
  text-align: center;
  background-color: white;
  margin-top: 6%;
  margin-bottom: 6%;
  border: 2px solid rgb(102, 209, 229);
  border-radius: 10px;
  padding: 20px;
}

select,
input {
  width: 90%;
  height: 4vh;
  margin-top: 1vh;
  margin-bottom: 1vh;
}

.erase-button {
  font-size: 1.2vw;
  color: white;
  background-color: rgb(102, 209, 229);
  border: none;
  border-radius: 5px;
  height: 5vh;
  width: 40%;
  margin-bottom: 2vh;
}

button {
  font-size: 1.5vw;
  color: white;
  background-color: rgb(102, 209, 229);
  border: none;
  border-radius: 5px;
  height: 10vh;
  width: 80%;
}

.disclaimer {
  margin-top: 3vh;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 0.8vw;
  display: block;
  color: black;
  font-weight: bold;
}

@font-face {
  font-family: Galisteo;
  src: url("./fonts/Galisteo.otf") format("opentype");
}

@media (max-width: 500px) {
  .container {
    background-image: url(./images/Asset.png);
    background-size: auto;
    overflow: auto;
    display: grid;
    grid-template-columns: 100vw;
    grid-template-areas: "form-border";
    color: rgb(102, 209, 229);
  }

  .label-title {
    font-size: 6vw;
    font-weight: bold;
    
  }
  .form-label {
    display: block;
    text-align: left;
    font-size: 4vw;
    font-weight: bold;
  }
  .form-border {
    grid-area: form-border;
    display: grid;
    grid-template-columns: 6% 88% 6%;
    grid-template-areas:
      ". . ."
      ". form."
      ". . .";
      border:none
  }

  .form-container {
    grid-area: form;
    text-align: center;
    background-color: white;
    margin-top: 6%;
    margin-bottom: 6%;
    border: 2px solid rgb(102, 209, 229);
    border-radius: 10px;
    padding: 20px;
  }

  select,
  input {
    width: 90%;
    height: 4vh;
    margin-top: 2vh;
    margin-bottom: 2vh;
  }

  .erase-button {
    font-size: 3vw;
    color: white;
    background-color: rgb(102, 209, 229);
    border: none;
    border-radius: 5px;
    height: 5vh;
    width: 40%;
    margin-bottom: 2vh;
  }

  button {
    font-size: 5vw;
    color: white;
    background-color: rgb(102, 209, 229);
    border: none;
    border-radius: 5px;
    height: 10vh;
    width: 80%;
  }

  .disclaimer {
    margin-top: 3vh;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.6vw;
    display: block;
    color: black;
    font-weight: bold;
  }
}
