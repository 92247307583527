.suggestions{
    color:black;
}


.react-autosuggest__suggestions-container--open {
   
    position: absolute;
    border: 2px solid #aaa;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 10;
    max-height: 20vh;
    overflow:auto;
    margin-top: 16px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
  
  .react-autosuggest__suggestions-list{
    list-style: none;
    margin-bottom: 0px;
    padding-left: 0px;
    cursor: pointer;
  }
  
  .react-autosuggest__input{
    width: 500px;
  }
  
  .react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
  }